var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"publish-settings"},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveChannelConfig}},[_vm._v(" "+_vm._s(_vm.$t('save_active_channel_config'))+" ")])]},proxy:true}])}),_c('slots',{attrs:{"id":"slots"}}),_c('a-card',{staticClass:"mt-5"},[_c('a-alert',[_c('div',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('post_style_mini_help_message'))},slot:"description"})]),_c('create-if-empty',{attrs:{"options":_vm.$store.getters.stylesOptions,"empty-message":_vm.$t('styles_create_empty_message')},on:{"onCreateClick":_vm.gotoStyleCreate}},[_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'text_post_style',
            'options': _vm.$store.getters.stylesOptions,
            'multiple': false,
            'clearable': false,
          },
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'media_post_style',
            'options': _vm.$store.getters.stylesOptions,
            'multiple': false,
            'clearable': false,
          },
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'album_post_style',
            'options': _vm.$store.getters.stylesOptions,
            'multiple': false,
            'clearable': false,
          },
        }}})],1)],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_user_forwards',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_channel_forwards',
        },
      }}}),_c('black-white-list-input',{staticClass:"mt-8",attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_channel_list',
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }